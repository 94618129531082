<template>
  <form 
    class="is-flex is-flex-direction-column is-align-content-center" 
    :style="{
      'max-width': '540px',
      'margin': '0 auto'
    }"
    method="post"
    action="/auth/token/"
    @submit.prevent="handleSubmit"
    autocomplete="on"
  >
    <div class="field is-narrow">
      <p class="control">
        <input 
          v-model="username"
          id="sales-username"
          name="sales-username"
          class="input" 
          type="text" 
          placeholder="Username"
        >
        <!-- <span class="icon is-small is-left">
          <i class="fas fa-envelope"></i>
        </span>
        <span class="icon is-small is-right">
          <i class="fas fa-check"></i>
        </span> -->
      </p>
      <p v-if="error" class="help is-danger">Ошибка</p>
    </div>
    <div class="field">
      <p class="control">
        <input 
          v-model="password"
          class="input" 
          type="password" 
          placeholder="Password"
        >
        <!-- <span class="icon is-small is-left">
          <i class="fas fa-lock"></i>
        </span> -->
      </p>
      <p v-if="error" class="help is-danger">Ошибка</p>
    </div>
    
    <div class="field">
      <div class="control">
        <input 
          class="button is-danger is-outlined"
          :class="{
            'is-loading': loading,
          }"
          :disabled="loading"
          type="submit"
          value="Вход"
        >
          
      </div>
      
    </div>
  </form>
</template>

<script>
import axios from 'axios'
export default {
  data () {
    return {
      username: '',
      password: '',
      submitted: false,
      loading: false,
      error: false,
    }
  },
  methods: {
    handleSubmit() {
      this.loading = true;
      this.error = false;
      const { username, password } = this;

      axios
        .post("/auth/token/", {
          username, 
          password,
        })
        .then((res) => {
          if (res.data && res.data.access && res.data.refresh) {
            this.$store.dispatch("session/set", { token: res.data.access, refresh: res.data.refresh });
            // this.$store.dispatch("user/init", res.data.user);
            this.$router.push("/");
          } else throw new Error("Ошибка ответа");
        })
        .catch(() => {
          this.error = true
          // this.errors.push(err.response && err.response.data && err.response.data.message || "Ошибка")
          this.loginPassword = ''
        })
        .finally(() => {
          this.loading = false;
        });
      this.loading = false
    },
  }
}
</script>

<style>

</style>