<template>
  <div>
    <div :style="{ 'max-width': '540px', margin: '0 auto' }">
      <form
        id="paymentcheck"
        class="is-flex is-flex-direction-column is-align-content-center my-3"
        method="post"
        @submit.prevent="fetchUserByPhone"
      >
        <label for="phonenum" class="has-text-centered">Создание заказа</label>
        <input
          v-model="phone"
          class="input mt-2"
          id="phonenum"
          type="text"
          :disabled="loading"
          placeholder="Номер телефона"
          v-mask="'+ 7 (###) ###-##-##'"
        />
        <input
          type="submit"
          class="button mt-3"
          :class="{
            loading: loading,
          }"
          :disabled="loading"
          value="Проверить"
        />
        <div v-if="userError">
          <p class="has-text-danger">
            {{ userNoResult ? "Пользователь не найден" : "Ошибка запроса" }}
          </p>
        </div>
      </form>
      <div v-if="userData">
        <p class="has-text-success">Пользователь найден!</p>
        <p v-if="userData">Имя: {{ userData.full_name || userData }}</p>
        <p v-if="userPhone">Телефон: {{ userPhone }}</p>
      </div>
      <!-- <div>
        <button
          class="button is-danger"
          v-if="userPhone"
          @click="sendInstructions"
        >
          Отправить смс инструкцию
        </button>
        <p
          v-if="smsError"
          class="has-text-danger"
        >{{ smsError }}</p>
        <p
          v-if="smsSent"
          class="has-text-success"
        >СМС отправлен!</p>
      </div>-->
    </div>
    <!--  -->
    <div class="is-flex is-flex-direction-column is-align-content-center mt-5">
      <h1
        v-if="userNoCourses"
        class="is-size-5 has-text-centered has-text-info"
      >
        Пользователь без курсов!
      </h1>
      <h1
        v-if="userCoursesError"
        class="is-size-5 has-text-centered has-text-danger"
      >
        Ошибка при запросе курсов!
      </h1>
      <div class="has-text-centered">
        <button
          class="button is-danger"
          v-if="userPhone && userData"
          @click="sendCourseReadySMS"
        >
          <!-- v-if="userPhone && userNoCourses" -->
          Подтвердить Оплату (СМС)
        </button>
        <p v-if="smsReadyError" class="has-text-danger">{{ smsError }}</p>
        <p v-if="smsReadySent" class="has-text-success">СМС отправлен!</p>
      </div>

      <div class="has-text-centered" v-if="userCoursesData">
        <h1 v-if="userResultsData" class="is-size-3 has-text-centered">
          Курсы ученика
        </h1>
        <table class="table" style="margin: 0 auto">
          <thead>
            <tr>
              <td>Дата</td>
              <td>Предмет 1</td>
              <td>Предмет 2</td>
              <td v-if="userCoursesData.type === 1">Текущий марафон</td>
            </tr>
          </thead>
          <tbody>
            <tr>
              <td>
                {{
                  new Date(userCoursesData.created_at).toLocaleDateString(
                    "en-GB"
                  )
                }}
              </td>
              <td>
                {{
                  (availableCourses &&
                    availableCourses.find(
                      (el) => el.id === userCoursesData.subject_1_id
                    ) &&
                    availableCourses.find(
                      (el) => el.id === userCoursesData.subject_1_id
                    ).name_ru) ||
                  userCoursesData.subject_1_id
                }}
              </td>
              <td>
                {{
                  (availableCourses &&
                    availableCourses.find(
                      (el) => el.id === userCoursesData.subject_2_id
                    ) &&
                    availableCourses.find(
                      (el) => el.id === userCoursesData.subject_2_id
                    ).name_ru) ||
                  userCoursesData.subject_2_id
                }}
              </td>
              <td v-if="userCoursesData.type === 1">
                {{
                  (availableMarathons &&
                      availableMarathons.find(
                          (el) => el.id === userCoursesData.current_marathon
                      ).start_date
                  )
                }}
                —
                {{
                  (availableMarathons &&
                      availableMarathons.find(
                          (el) => el.id === userCoursesData.current_marathon
                      ).end_date
                  )
                }}
              </td>
            </tr>
          </tbody>
          <tfoot v-if="!availableCourses">
            <tr>
              <td colspan="3">
                <i>Показаны ID т.к. не удалось загрузить названия предметов</i>
              </td>
            </tr>
          </tfoot>
        </table>
      </div>

      <div class="has-text-centered my-4" v-if="userCoursesData && userCoursesData.type === 1">
        <h1 class="is-size-3 has-text-centered">Повторная запись на марафон</h1>
        <div class="dropdown" :class="{ 'is-active': dropdownFourActive }">
          <div class="dropdown-trigger is-flex is-align-items-center">
            <div class="mr-2">Марафон:</div>
            <button
                class="button"
                aria-haspopup="true"
                aria-controls="dropdown-menu"
                @click="dropdownFourActive = !dropdownFourActive"
            >
                <span
                >{{ selectedMarathon ? `${selectedMarathon.start_date} — ${selectedMarathon.end_date}` : "Выбрать марафон" }}
                </span>
            </button>
          </div>
          <div class="dropdown-menu" id="dropdown-menu" role="menu">
            <div class="dropdown-content">
              <a
                  class="dropdown-item"
                  v-for="item in availableMarathons"
                  :key="item.id"
                  @click.prevent="
                    selectedMarathon = item;
                    dropdownFourActive = false;
                  "
              >
                {{ `${item.start_date} — ${item.end_date}` }}
              </a>
            </div>
          </div>
        </div>
        <div class="mt-2">
          <button
              class="button is-danger mt-3"
              @click="reEnrollMarathon"
              :disabled="!selectedMarathon || loading"
          >
            Записать на марафон
          </button>
        </div>
        <p v-if="enrollError" class="has-text-danger">{{ enrollError }}</p>
        <p v-if="enrolled" class="has-text-success">Пользователь записан!</p>
      </div>

      <div class="has-text-centered" v-if="userNoCourses">
        <h1 class="is-size-3 has-text-centered">Запись на курс</h1>
        <div class="tabs is-toggle is-centered is-boxed mt-3">
          <ul>
            <li
              v-for="tab in tabs"
              :key="tab.id"
              :class="{ 'is-active': currentTab === tab.id }"
              @click="currentTab = tab.id"
            >
              <a>{{ tab.title }}</a>
            </li>
          </ul>
        </div>
        <div class="is-flex is-flex-direction-column is-align-items-center">
          <p>Выбор пары предметов:</p>
          <div class="dropdown" :class="{ 'is-active': dropdownOneActive }">
            <div class="dropdown-trigger">
              <button
                class="button"
                aria-haspopup="true"
                aria-controls="dropdown-menu"
                @click="dropdownOneActive = !dropdownOneActive"
              >
                <span>{{
                  selectedCourseOne && selectedCourseTwo
                    ? selectedCourseOne.id === null &&
                      selectedCourseTwo.id === null
                      ? "Стандарт(Обязательные предметы ЕНТ)"
                      : `${selectedCourseOne.name_ru} - ${selectedCourseTwo.name_ru}`
                    : "Выбрать курс"
                }}</span>
                <span class="icon is-small">
                  <i class="fas fa-angle-down" aria-hidden="true"></i>
                </span>
              </button>
            </div>
            <div class="dropdown-menu" id="dropdown-menu" role="menu">
              <div class="dropdown-content">
                <template
                  v-for="(coursePair, pairKey) in availableCoursesPairs"
                >
                  <!-- v-if="!selectedCourseOne || (course.id !== selectedCourseOne.id)" -->
                  <a
                    :key="'dd1_' + pairKey"
                    class="dropdown-item"
                    @click.prevent="
                      selectedCourseOne = coursePair.subject_1;
                      selectedCourseTwo = coursePair.subject_2;
                      dropdownOneActive = false;
                    "
                    >{{
                      coursePair.subject_1.name_ru +
                      " - " +
                      coursePair.subject_2.name_ru
                    }}
                  </a>
                </template>
                <a
                  class="dropdown-item"
                  @click="
                    selectedCourseOne = { id: null };
                    selectedCourseTwo = { id: null };
                    dropdownOneActive = false;
                  "
                  >Стандарт(Обязательные предметы ЕНТ)</a
                >
              </div>
            </div>
          </div>
          <p class="mt-3">Язык:</p>
          <div
            class="dropdown"
            :class="{
              'is-active': dropdownThreeActive,
            }"
          >
            <div class="dropdown-trigger">
              <button
                class="button"
                aria-haspopup="true"
                aria-controls="dropdown-menu"
                @click="dropdownThreeActive = !dropdownThreeActive"
              >
                <span>
                  {{
                    selectedLanguageCode
                      ? availableLanguagesMap[selectedLanguageCode] ||
                        selectedLanguageCode
                      : "Выбрать Язык"
                  }}
                </span>
                <span class="icon is-small">
                  <i class="fas fa-angle-down" aria-hidden="true"></i>
                </span>
              </button>
            </div>
            <div class="dropdown-menu" id="dropdown-menu" role="menu">
              <div class="dropdown-content">
                <a
                  v-for="lang_key in Object.keys(availableLanguagesMap)"
                  :key="'dd3_' + lang_key"
                  class="dropdown-item"
                  @click.prevent="
                    selectedLanguageCode = lang_key;
                    dropdownThreeActive = false;
                  "
                  >{{ availableLanguagesMap[lang_key] }}
                </a>
              </div>
            </div>
          </div>
          <p class="mt-3" v-if="currentTab === 2">Марафоны:</p>
          <div class="dropdown" :class="{ 'is-active': dropdownFourActive }" v-if="currentTab === 2">
            <div class="dropdown-trigger">
              <button
                class="button"
                aria-haspopup="true"
                aria-controls="dropdown-menu"
                @click="dropdownFourActive = !dropdownFourActive"
              >
                <span
                  >{{ selectedMarathon ? `${selectedMarathon.start_date} — ${selectedMarathon.end_date}` : "Выбрать марафон" }}
                </span>
              </button>
            </div>
            <div class="dropdown-menu" id="dropdown-menu" role="menu">
              <div class="dropdown-content">
                <a
                  class="dropdown-item"
                  v-for="item in availableMarathons"
                  :key="item.id"
                  @click.prevent="
                    selectedMarathon = item;
                    dropdownFourActive = false;
                  "
                >
                  {{ `${item.start_date} — ${item.end_date}` }}
                </a>
              </div>
            </div>
          </div>
          <button
            class="button is-danger mt-3"
            @click="handleEnrollClick"
            v-if="currentTab === 1"
            :disabled="
              !selectedCourseOne ||
              !selectedCourseTwo ||
              !selectedLanguageCode ||
              loading
            "
          >
            Записать на курс
          </button>
          <button
            class="button is-danger mt-3"
            @click="signUpMarathon"
            v-if="currentTab === 2"
            :disabled="
              !selectedCourseOne ||
              !selectedCourseTwo ||
              !selectedLanguageCode ||
              !selectedMarathon ||
              loading
            "
          >
            Записать на марафон
          </button>
          <button
            class="button is-danger mt-3"
            @click="enrollOfflineCourse"
            v-if="currentTab === 3"
            :disabled="
              !selectedCourseOne ||
              !selectedCourseTwo ||
              !selectedLanguageCode ||
              loading
            "
          >
            Записать на оффлайн курс
          </button>
        </div>
        <p v-if="enrollError" class="has-text-danger">{{ enrollError }}</p>
        <p v-if="enrolled" class="has-text-success">Пользователь записан!</p>
      </div>

      <!-- Если запрос 404, то ученик не записан на курс,
      оператор заполняет форму для записи ученика
      на курсы POST /sales/course_students/.
      После этого он отправляет СМС, что оплата принята и ученик записан на курс  /sales/user/{phone}/course_ready_sms/. -->
      <!-- POST /sales/course_students/ -->
      <!-- /sales/user/{phone}/course_ready_sms/  -->
      <h1 v-if="userResultsData" class="is-size-3 has-text-centered mt-5">
        Результаты ученика
      </h1>
      <results-table :results="userResultsData" />
    </div>
  </div>
</template>

<script>
import axios from "axios";
import resultsTable from "@/components/partials/resultsTable.vue";
export default {
  components: {
    resultsTable,
  },
  data() {
    return {
      loading: false,
      phone: "",
      userNoResult: false,
      userError: false,
      userPhone: undefined,
      userData: undefined,
      userResultsData: undefined,
      userNoCourses: false,
      userCoursesData: undefined,
      userCoursesError: false,
      smsError: "",
      smsSent: false,
      smsReadySent: false,
      smsReadyError: false,
      enrollError: "",
      enrolled: false,
      availableCourses: undefined,
      availableCoursesPairs: undefined,
      availableMarathons: null,
      selectedMarathon: null,
      selectedCourseOne: undefined,
      selectedCourseTwo: undefined,
      availableLanguagesMap: {
        kk: "Казахский",
        ru: "Русский",
      },
      selectedLanguageCode: undefined,
      dropdownOneActive: false,
      dropdownTwoActive: false,
      dropdownThreeActive: false,
      dropdownFourActive: false,
      currentTab: 1,
      tabs: [
        {
          id: 1,
          title: "Курс"
        },
        {
          id: 2,
          title: "Марафон"
        },
        {
          id: 3,
          title: "Оффлайн курс",
        },
      ],
    };
  },
  computed: {},
  // computed: {
  // filteredAvailableCourses(){
  //   try {
  //     let courses = this.availableCourses;
  //     let first_id = this.selectedCourseOne.id;
  //     let available_ids = this.availableCoursesPairs.reduce((acc, cur) => {
  //       if (cur.subject_1 === first_id){
  //         acc.push(cur.subject_2)
  //       } else if (cur.subject_2 === first_id){
  //         acc.push(cur.subject_1);
  //       }
  //       return acc;
  //     }, [])
  //     let available_courses = courses.filter((course) => (available_ids.includes(course.id)))
  //     return available_courses;
  //   } catch {
  //     return []
  //   }
  // },
  // },
  methods: {
    // getCourseNameFromId(id){
    //   let courseId
    //   try{
    //     courseId = this.availableCourses.findIndex((course) => (course.id === id));
    //   } catch {
    //     console.error('Couldnt get Course Name')
    //     courseId = undefined
    //   }
    //   return this.availableCourses && this.availableCourses[courseId] && this.availableCourses[courseId].name_ru || id;
    // },

    async fetchUserByPhone() {
      this.loading = true;
      this.userNoResult = false;
      this.userError = false;
      this.userData = undefined;
      this.userResultsData = undefined;
      this.smsError = "";
      this.smsSent = false;
      this.userCoursesError = false;
      this.userCoursesData = undefined;
      this.userNoCourses = false;
      this.smsReadySent = false;
      this.smsReadyError = "";
      this.enrollError = "";
      this.enrolled = false;
      this.selectedCourseOne = undefined;
      this.selectedCourseTwo = undefined;
      this.selectedLanguageCode = undefined;

      let { phone } = this;
      this.userPhone = "+" + phone.replace(/[^0-9.]/g, "");

      await axios
        .get(`/sales/user/${this.userPhone}/`)
        .then((res) => {
          this.userData = res.data;
          this.fetchUserResultsByPhone(this.userPhone);
          if (this.userData.id) {
            this.fetchUserCoursesById(this.userData.id);
          }
        })
        .catch((err) => {
          this.userError = true;
          if (err.response && err.response.status === 404) {
            this.userNoResult = true;
          }
        })
        .finally(() => {
          this.loading = false;
        });
    },
    // +77059145022
    // +77075015855
    async fetchUserCoursesById(id) {
      this.userCoursesError = false;
      this.userNoCourses = false;
      await axios
        .get(`/courses/sales/course_students/${id}/`)
        .then((res) => {
          this.userCoursesData = res.data;
        })
        .catch((err) => {
          if (err.response && err.response.status === 404) {
            this.userNoCourses = true;
          } else {
            console.error(err);
            this.userCoursesError = true;
          }
        });
    },

    async fetchAvailableCourses() {
      await axios.get("/courses/subjects/").then((res) => {
        if (res && res.data) {
          this.availableCourses = res.data.results;
        }
      });
    },

    async fetchAvailableMarathons() {
      await axios.get("/courses/sales/marathons/").then((res) => {
        if (res && res.data) {
          this.availableMarathons = res.data;
        }
      });
    },

    async fetchAvailableCoursesPairs() {
      await axios.get("/courses/subjects_pairs/").then((res) => {
        if (res && res.data) {
          this.availableCoursesPairs = res.data;
        }
      });
    },

    async fetchUserResultsByPhone(phone) {
      await axios
        .get(`/sales/user/${phone}/results/`)
        .then((res) => {
          this.userResultsData = res.data;
        })
        .catch((err) => {
          console.error(err);
        });
    },

    // async sendInstructions(){
    //   this.smsError = ''
    //   this.smsSent = false;
    //   this.loading = true;
    //   await axios.post(`/sales/user/${this.userPhone}/course_payment_sms/`)
    //     .then((res) => {
    //       this.smsSent = true;
    //     })
    //     .catch((error) => {
    //       this.loading = true;
    //       console.error(error);
    //       this.smsError = "Ошибка отправки: "
    //         + (error.response ? error.response.status : error)
    //     }).finally(() => {
    //       this.loading = false;
    //     })
    // },

    async signUpMarathon() {
      this.loading = true;
      this.enrollError = "";
      await axios.post("/courses/sales/course_students/marathon/", {
        language: this.selectedLanguageCode,
        student_id: this.userData && this.userData.id,
        subject_1_id: this.selectedCourseOne.id,
        subject_2_id: this.selectedCourseTwo.id,
        marathon_id: this.selectedMarathon.id
      })
      .then(() => {
          this.enrolled = true;
        })
        .catch((error) => {
          this.loading = true;
          console.error(error);
          this.enrollError =
            "Ошибка отправки: " +
            (error.response ? error.response.status : error);
        })
        .finally(() => {
          this.loading = false;
        });
    },

    async reEnrollMarathon() {
      this.loading = true;
      this.enrollError = "";
      await axios
          .post("/courses/sales/course_students/marathon/", {
            language: this.userCoursesData.language,
            student_id: this.userData && this.userData.id,
            subject_1_id: this.userCoursesData.subject_1_id,
            subject_2_id: this.userCoursesData.subject_2_id,
            marathon_id: this.selectedMarathon.id
          })
          .then(() => {
            this.enrolled = true;
          })
          .catch((error) => {
            this.loading = true;
            console.error(error);
            this.enrollError =
                "Ошибка отправки: " +
                (error.response ? error.response.status : error);
          })
          .finally(() => {
            this.loading = false;
          });
    },

    async handleEnrollClick() {
      this.loading = true;
      this.enrollError = "";
      // subject_1_id
      await axios
        .post("/courses/sales/course_students/", {
          language: this.selectedLanguageCode,
          student_id: this.userData && this.userData.id,
          subject_1_id: this.selectedCourseOne.id,
          subject_2_id: this.selectedCourseTwo.id,
          // language: 'kk'
        })
        .then(() => {
          this.enrolled = true;
        })
        .catch((error) => {
          this.loading = true;
          console.error(error);
          this.enrollError =
            "Ошибка отправки: " +
            (error.response ? error.response.status : error);
        })
        .finally(() => {
          this.loading = false;
        });
      // PARAMS {
      // student_id*	integer
      // title: Student id
      // subject_1_id*	integer
      // title: Subject 1 id
      // subject_2_id*	integer
      // title: Subject 2 id
      // language*	string
      // title: Language
      // minLength: 1
      // }
      // RES {
      // student_id*	integer
      // title: Student id
      // subject_1_id*	integer
      // title: Subject 1 id
      // subject_2_id*	integer
      // title: Subject 2 id
      // language*	string
      // title: Language
      // minLength: 1
      // }
    },
    async sendCourseReadySMS() {
      this.loading = true;
      this.smsReadyError = "";
      this.smsReadySent = false;
      await axios
        .post(`/sales/user/${this.userPhone}/course_ready_sms/`)
        .then(() => {
          this.smsReadySent = true;
        })
        .catch((error) => {
          this.loading = true;
          console.error(error);
          this.smsReadyError =
            "Ошибка отправки: " +
            (error.response ? error.response.status : error);
        })
        .finally(() => {
          this.loading = false;
        });
    },
    async enrollOfflineCourse() {
      this.loading = true;
      this.enrollError = "";

      await axios
        .post("/courses/sales/course_students/offline/", {
          language: this.selectedLanguageCode,
          student_id: this.userData && this.userData.id,
          subject_1_id: this.selectedCourseOne.id,
          subject_2_id: this.selectedCourseTwo.id,
        })
        .then(() => {
          this.enrolled = true;
        })
        .catch((error) => {
          this.loading = true;
          console.error(error);
          this.enrollError =
            "Ошибка отправки: " +
            (error.response ? error.response.status : error);
        })
        .finally(() => {
          this.loading = false;
        });
    },
  },
  mounted() {
    this.fetchAvailableCourses().then(() => {
      this.fetchAvailableCoursesPairs();
      this.fetchAvailableMarathons();
    });
  },
};
</script>
