<template>
  <div class="container is-max-desktop">
    <template v-if="!groups">
      <h1>Запрос роли...</h1>
    </template>
    <template v-if="groups && groups.some(role => role === 'sales-person-front')">
      <SalesPersonFront />   
    </template>
    <template v-if="groups && groups.some(role => role === 'sales-person-back')">
      <SalesPersonBack />
    </template>
  </div>
</template>

<script>
import axios from 'axios'
import { mapGetters } from 'vuex'
import SalesPersonBack from '@/components/SalesPersonBack'
import SalesPersonFront from '@/components/SalesPersonFront'
export default {
  name: 'Root',
  components: {
    SalesPersonBack,
    SalesPersonFront,
  },
  data(){
    return {

    }
  },
  computed: {
    ...mapGetters('user', [
      'groups',
    ])
  },
  mounted(){
    axios.get('/identity/profile/').then((res) => {
      this.$store.dispatch('user/init', res.data);
    })
  }
}
</script>