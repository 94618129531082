<template>
  <div>
    <!--  -->
    <div 
      :style="{
        'max-width': '540px',
        'margin': '0 auto'
      }"
    >
      <form 
        id="paymentcheck"
        class="is-flex is-flex-direction-column is-align-content-center my-3" 
        
        method="post"
        @submit.prevent="fetchUserByPhone">
        <label 
          for="phonenum"
          class="has-text-centered"
        >Создание заказа</label>
        <input 
          v-model="phone"
          class="input mt-2" 
          id="phonenum" 
          type="text" 
          :disabled="this.loading"
          placeholder="Номер телефона"
          v-mask="'+ 7 (###) ###-##-##'"
        >
        <input 
          type="submit" 
          class="button mt-3" 
          :disabled="this.loading"
          value="Проверить"
        >
        <div v-if="userError">
          <p class="has-text-danger">
            {{
              userNoResult ? "Пользователь не найден" : "Ошибка запроса"
            }}
          </p>
        </div>
      </form>
      <div v-if="userData">
        <p class="has-text-success">Пользователь найден!</p>
        <p 
          v-if="userData"
        >
          Имя: {{ userData.full_name || userData }}
        </p>
        <p 
          v-if="userPhone"
        >Телефон: {{ userPhone }}</p>
      </div>
      <div>
        <button 
          class="button "
          v-if="userPhone"
          @click="sendInstructions"
        >
          Отправить смс инструкцию
        </button>
        <p 
          v-if="smsError"
          class="has-text-danger"
        >{{ smsError }}</p>
        <p 
          v-if="smsSent"
          class="has-text-success"
        >СМС отправлен!</p>
      </div>
    </div>
    <!--  -->
    <div class="is-flex is-flex-direction-column is-align-content-center mt-5">
      <h1 
        v-if="userResultsData"
        class="is-size-3 has-text-centered"
      >Результаты ученика</h1>
      <results-table 
        :results="userResultsData"
        @showModal="handleTableResultsClick"
      />
    </div>
    <div 
      v-if="resultsModal && resultsData"
      class="modal is-active"
    >
      <div class="modal-background"></div>
      <div class="modal-card">
        <header class="modal-card-head">
          <p class="modal-card-title">Резултаты</p>
          <button 
            class="delete" 
            aria-label="close"
            @click="handleTableResultsClose"
          ></button>
        </header>
        <section class="modal-card-body">
          <!-- Content ... -->
          <ul>
            <li 

              v-for="key in Object.keys(resultsData)"
              :key="key"
            >
              {{ key + ': ' + resultsData[key]}}

            </li>
          </ul>
          
        </section>
      </div>
    </div>
  </div>
</template>

<script>
import axios from 'axios';
import resultsTable from './partials/resultsTable.vue';

export default {
  components: { resultsTable },
  data(){
    return {
      loading: false,
      phone: '',
      userNoResult: false,
      userError: false,
      userPhone: undefined,
      userData: undefined,
      userResultsData: undefined,
      smsError: '',
      smsSent: false,
      resultsModal: false,
      resultsData: undefined
    }
  },
  methods: {
    async fetchUserByPhone(){
      this.loading = true;
      this.userNoResult = false;
      this.userError = false;
      this.userData = undefined;
      this.userResultsData = undefined;
      this.smsError = ''
      this.smsSent = false;
      let { phone }  = this
      this.userPhone = "+" + phone.replace(/[^0-9.]/g, "");

      await axios.get(`/sales/user/${this.userPhone}`).then((res) => {
        this.userData = res.data;
        this.fetchUserResultsById(this.userPhone)
      }).catch((err) => {
        this.userError =  true;
        if (err.response && (err.response.status === 404)){
          this.userNoResult = true;
        }
      }).finally(() => {
        this.loading = false;
      })
    },
    // +77059145022
    // +77075015855
    async fetchUserResultsById(phone){
      await axios.get(`/sales/user/${phone}/results/`).then((res) => {
        this.userResultsData = res.data
      }).catch((err) => {
        console.error(err);
      })
    },

    async sendInstructions(){
      this.smsError = ''
      this.smsSent = false;
      await axios.post(`/sales/user/${this.userPhone}/course_payment_sms/`)
        .then(() => {
          this.smsSent = true;
        })
        .catch((error) => {
          console.error(error);
          this.smsError = "Ошибка отправки: " 
            + (error.response ? error.response.status : error)
        })
    },

    handleTableResultsClick(results){
      this.resultsModal = true;
      this.resultsData = results
    },

    handleTableResultsClose(){
      this.resultsModal = false;
      this.resultsData = undefined;
    },
  }
}
</script>