<template>
  <div class="table-container">
    <table 
      v-if="results"
      class="table"
      style="margin: 0 auto;"  
    >
      <thead>
        <tr>
          <th>Наименование</th>
          <th>ФИО</th>
          <th>Регистрация</th>
          <th>Результат</th>
        </tr>
      </thead>
      <tbody>
        <tr 
          v-for="(result, rkey) in results"
          :key="rkey"
        >
          <td>{{result.name_kk}}<br>{{result.name_ru}}</td>
          <td>
            {{ 
              (result.result && result.result.full_namee)
              || (
                result.registration 
                && (result.registration.last_name + ' ' + result.registration.first_name + ' ' + result.registration.patronymic)
              ) 
              || '-'
            }}
          </td>
          <td>
            {{ result.registration ? 'Есть' : 'Нет' }} 
          </td>
          <td>
            <span 
              v-if="result.result"
              class="has-text-weight-medium"
            >
              {{ 
                (result.result.total_score) 
                ? ('Общий: ' + result.result.total_score) 
                : '-'
              }}

            </span>
            <template 
              v-if="result.result && result.result.subjects"
            >
              <template 
                v-for="(subject, skey) in result.result.subjects"
              >
                <br :key="'br_'+rkey+'_'+skey">
                <span :key="'span_'+rkey+'_'+skey">
                  {{subject['nct_name_ru'] + ': '+ subject['total_score']}}
                </span>
              </template>
            </template>  
          </td>
        </tr>
      </tbody>
    </table>
  </div>
</template>

<script>
export default {
  props: [
    'results'
  ]
}
</script>

<style>

</style>